.PageLayout {
  height: auto;
  position: relative;
  width: 100%;
}

.AboutCaseStudy {
  display: flex;

  width: 92%;
  /* top: 0; */
  margin-left: 5%;
  /* margin-right: 10%; */
  margin-top: 60px;
  flex-direction: column;
}

.AboutCaseStudy p {
  font-size: 20px;
  position: relative;
  margin-top: -1em;
}
.videoframe{
  height:40em
}

.AboutCaseStudy h2 {
  font-size: 50px;
  position: absolute;
  font-weight: 600;
}

.titleLogo {
  width: auto;
  height: 2%;
  border: 2px solid black;
}

.playVideo {
  display: grid;
  grid-template-columns: 2fr;
  height: auto;
  /* display: flex; */
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-items: center;
  align-items: stretch;
}

.VideoBgSvg {
  width: 23em;
  grid-column: 1/3;
  grid-row: 1/2;
  /* height: 32%; */
  /* margin-left: 109em; */
  /* position: absolute; */
  margin-right: -56em;
  margin-top: -6em;
}

.Video {
  grid-column: 1/3;
  grid-row: 1/2;
  height: 100%;
  display: flex;
  width: 100%;
  margin-top: 10%;
  flex-direction: row;
  justify-content: space-evenly;
  grid-column: 1/3;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
  margin-top: 10%;
}

.ClientRequirements {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.ClientRequirements h2 {
  font-size: 50px;
  font-weight: 600;
}

.ClientRequirements p {
  font-size: 20px;
}

.ProductKeyPoints {
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.LeftKeyPoints {
  border: 2px solid black;
  height: auto;
  width: 50%;
}

.RightKeyPoints {
  border: 2px solid black;
  height: aut;
  width: 50%;
}

.Challenge {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.Challenge h2 {
  font-size: 50px;
  font-weight: 600;
}

.Challenge p {
  font-size: 20px;
  color: gray;
}

.ProductWorkDone {
  background-size: cover;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #615e5ef7, #f7ebeb40),
    url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy9GUj1Qd4jVvWrzqX5-HvV8i-X90QZCSRjg&usqp=CAU);
  margin-top: 10%;
  display: flex;
  height: 21em;
  width: 90%;
  grid-column: 1/3;
  /* grid-row: 1/2; */
  /* grid-column: 1/3; */
  /* grid-row: 1/2; */
  grid-column: 1/3;
  grid-row: 1/3;
  grid-template-columns: 2fr;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.Workdoneimage {
  height: 21em;
  margin-top: 10%;
  width: 95%;
}

.ProductWorkDone h4 {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
  margin-left: 2%;
  color: white;
}

.WorkdoneImage {
  height: 21em;
  margin-top: 10%;
  width: 95%;
  position: absolute;
  border-radius: 39px;
}

.ProductSpecialization {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.ProductSpecialization h2 {
  font-size: 50px;
  margin-bottom: 10px;
  font-weight: 600;
}

.ProductSpecialization p {
  font-size: 20px;
}

.ProductImages {
  grid-template-columns: 2fr;
  margin-top: 10%;
  height: auto;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex-direction: column;
  align-content: space-between;
}

.TopImages {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BottomImage {
  height: 25em;
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;

  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

.TopRightImage {
  display: flex;
  width: 40%;
  justify-content: flex-start;
}

.TopRightImage img {
  width: 95%;
  border-radius: 10% 10% 0 0;
}

.TopLeftImage {
  width: 55%;
  display: flex;
  justify-content: flex-end;
}
.FullWidthImage {
  width: 100%;
  display: flex;
  justify-content: center;
}
.FullWidthImage img {
  width: 95%;
  border-radius: 10% 10% 0 0;
}


.TopLeftImage img {
  width: 95%;
  border-radius: 10% 10% 0 0;
}

.BottomImage img {
  width: 95%;
  height: 100%;
  border-radius: 0 0 5% 5%;
}

.TopBottomImages {
  margin-right: auto;
  margin-left: auto;
  grid-column: 1/3;
  grid-row: 1/2;
  display: flex;
  height: fit-content;
  width: 95%;
  /* height: auto; */
  flex-direction: column;
  /* position: absolute; */
}

.ClientFeedback {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  height: auto;
}

.ClientFeedback h2 {
  font-size: 50px;

  font-weight: 600;
}

.feedback {
  width: 100%;
  display: -webkit-inline-box;
  scroll-behavior: smooth;
  overflow-x: scroll;
  margin-top: 3%;
}

.feedback::-webkit-scrollbar {
  display: none;
}

.allFeedbacks {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  width: 100%;
}

.feedbackImage {
  display: grid;

  grid-template-columns: 2fr;
  justify-content: center;
  flex-wrap: nowrap;
  justify-items: center;
}

.feedbackImage img {
  grid-column: 1/3;
  grid-row: 1/2;
  width: 74%;
  height: 81%;
  margin-left: 31%;
  border-radius: 50%;
  border: 5px solid white;
}

.feedbackText {
  margin-left: 7%;
}

.feedbackText p {
  margin-left: 2%;
  margin-top: 2%;
}

.MoreCaseStudies {
  height: auto;
  width: 95%;
  margin-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.MoreCaseStudiesHeader {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.Line {
  margin-left: auto;
  margin-right: auto;
  width: 51%;
  min-width: 38%;
  height: 1px;
  background: lightgray;
}

.MoreCaseStudiesHeader h2 {
  font-size: 50px;

  font-weight: 600;
}

.CaseStudiesImages {
  width: 95%;

  overflow-x: scroll;
  scroll-behavior: smooth;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;

  display: -webkit-inline-box;

  white-space: nowrap;
  height: auto;
}

.CaseStudiesImages::-webkit-scrollbar {
  display: none;
}

.Images,
.More {
  height: 31em;
  width: 31%;
  overflow: hidden;
  margin: 1em;
  white-space: normal;
  display: flex;
  /* border: 2px solid black; */
  justify-content: center;
  align-items: center;
  position: relative;
}

.Images h1 {
  /* font-size: 2em;
  transition: all 0.4s ease-out;

  margin-left: 6%;
  position: relative;
  margin-top: 30em;
  margin-bottom: 5%; */
}

.Images:hover>h1 {
  margin-top: 0;
}

.Images:hover>.caseStudyintro {
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.caseStudyintro {
  /* height: 0; */
  position: relative;
  transition: all 0.4s ease-out;

  overflow: hidden;
  /* visibility: hidden; */

  top: 100%;

  /* display: none; */
  margin-left: 5%;
}

.caseStudyintro p {
  margin-bottom: 7%;
}

.More {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.More p {
  margin-top: 11%;
  font-size: 31px;
  font-weight: 600;
}

/* added css */
.Images .hover-content {
  opacity: 0;
  transition: opacity 0.4s ease-out;
  /* background: rgba(0, 0, 0, 0.5);
  background: rgb(0 0, 231); */
  backdrop-filter: blur(10px);
  padding: 20px;
  text-align: center;
  max-width: 100%;
  /* Set maximum width */
  max-height: 100%;
  /* Set maximum height */
  width: 100%;
  /* Set maximum width */
  height: 100%;
  /* Set maximum height */
  overflow: hidden;
  /* Enable scrolling if content overflows */
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
}


.Images:hover .hover-content {
  opacity: 1;
}


.form-top-img img {
  grid-column: 1/3;
  grid-row: 1/2;
  max-width: 100%;
  animation-name: rotateClock;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.form-top-img {
  display: grid;

  grid-template-columns: 2fr;
}

.form-bottom-img img {
  grid-column: 1/3;
  grid-row: 1/2;
  max-width: 100%;
  animation-name: rotateClock;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.form-bottom-img {
  display: grid;

  grid-template-columns: 2fr;
}


.icon:hover {
  fill: black; /* Change to the color you want on hover */
  stroke: white;
}

.icon:hover path {
  stroke: white;
}


.keypoints {
  display: flex;
  width: 90%;
  margin-top: 10%;
}

.pointLogo {
  display: grid;
}

.pointDescription {
  display: flex;
  margin-left: 12%;
  flex-direction: column;

  flex-wrap: wrap;
  width: 100%;
}

.point_container {
  display: grid;
  width: 90%;
  margin-top: 4%;
  /* height: 100%; */
  grid-template-columns: auto auto;
  /* width: 100%; */
  justify-items: stretch;
}

.WorkedoneContainer {
  display: flex;

  flex-direction: column;
  margin-bottom: 1%;
  align-items: baseline;
}

.WorkdoneLayout {
  width: 100%;
  height: auto;
  display: grid;

  grid-template-rows: 2fr;
  grid-template-columns: 2fr;
}
@media (max-width:600px) {
 .allFeedbacks{
  flex-direction: column;
 }
 .CaseStudiesImages{
  display: flex;
  flex-direction: column;
 }
 .Images{
  width: unset!important;

 }
 .videoframe{
  height:15em
}
 .More{
 height: 15em;
 }
}