.ResultLayout {
  display: flex;
  margin-top: 20px;
  width: 100%;
  height: auto;
  align-content: space-around;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}

.topLine {
  width: 96%;
  height: 2px;
  background-color: #e6e6e6;
  margin-top: 10px;
}


.redirectArrow:hover .icon circle {
  fill: black; /* Change to the color you want on hover */
  stroke: white;
}

.redirectArrow:hover .icon circle {
  fill: black; /* Change to the color you want on hover */
  stroke: white;
}
.redirectArrow:hover path {
  stroke: white; /* Change path stroke color to white on hover */
}


.resultContent {
  background-color: white;
  cursor: default;
  display: flex;
  height: 100%;
  width: 97%;
  top: 0;
  margin-top: 46px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.Index {
  margin-left: 18px;
  font-size: 40px;
  font-weight: 500;
}

.IndustryName {
  color: #0e1d4b;

  font-size: 40px;
  font-weight: 700;
  margin-left: 1em;
}

.ServiceDescription {
  color: rgb(24 29 75);
  font-size: 20px;
  width: auto;
  height: 100%;
  margin-left: 49px;
}

.redirectArrow {
  display: flex;
  margin-right: 20px;
  cursor: pointer;
  width: 87px;
  margin-right: 10px;
  right: 0;
  margin-top: -6px;
  height: 83px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.IndexIndName {
  display: flex;
  flex-direction: row;
  width: 50%;

  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.Descriptionlink {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
@media (max-width:600px) {
  .Index{
    font-size: 25px;
  }
  .IndustryName{
    font-size: 25px;
  }
}
