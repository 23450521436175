.caseStudyMainContent {
  /* min-width: 1143px; */
  margin-top: 29px;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 300px;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 50px;
}


.caseStudyNavbar {
  position: sticky;
  top: 0;
  border-radius: 18px;
  width: 98.9%;
  height: 100px;
  border: 2px solid #e6e6e6;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.caseStudyNavContent1,
.caseStudyNavContent3 {
  height: 4.700000000000003em;
  width: 24em;
  display: flex;
  border: 2px solid #e6e6e6;
  border-radius: 18px;
  flex-wrap: nowrap;
  align-items: center;
}

.caseStudyNavContent2 {
  cursor: pointer;
  align-items: baseline;
  display: flex;
  border: 2px solid #e6e6e6;
  border-radius: 18px;
  height: 4.700000000000003em;
  width: 24em;
  flex-direction: row;
  flex-wrap: nowrap;
}

.caseStudyNavContent1 {
  cursor: pointer;
  align-items: baseline;
  display: flex;
  border: 2px solid #e6e6e6;
  border-radius: 18px;
  height: 4.700000000000003em;
  width: 24em;
  flex-direction: row;
  flex-wrap: nowrap;
}

.caseStudyNavdropdown {
  margin-bottom: 33px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}

.caseStudyNavContent2 p {
  margin-left: 5px;
  font-size: 20px;
  display: flex;
  font-weight: 600;
  justify-content: space-around;
  justify-content: flex-start;
}

.caseStudyNavContent1 p {
  margin-left: 5px;
  font-size: 20px;
  display: flex;
  font-weight: 600;
  justify-content: space-around;
  justify-content: flex-start;
}

.caseStudySearchbar {
  background: transparent;
  width: 90%;
  height: 98%;
  border-radius: 18px;
  margin-left: 15px;
  border: none;
  font-size: 19px;
  font-weight: 600;
}

.selectServiceContent {
  background-color: white;
  margin-top: 84.5px;
  position: absolute;
  width: 335px;
  border-right: 2px solid #e6e6e6;
  border-left: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
  max-height: 310px;
  overflow: scroll;
}

.selectServiceContent::-webkit-scrollbar {
  display: none;
}

.selectServiceItems {
  border-top: 2px solid #e6e6e6;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin-top: 0px;
  height: 62px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
}

.selectServiceItems:hover {
  background-color: rgb(210, 210, 210);
}

.navSelectIndustryDropdown {
  margin-bottom: 33px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}

.selectIndustryContent {
  background-color: white;

  margin-top: 84.5px;
  position: absolute;

  width: 335px;
  border-right: 2px solid #e6e6e6;
  border-left: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;

  /* height: 310px; */
  overflow: scroll;
}

.selectIndustryContent::-webkit-scrollbar {
  display: none;
}

.selectIndustryItems {
  border-top: 2px solid #e6e6e6;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin-top: 0px;
  height: 62px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
}

.selectIndustryItems:hover {
  background-color: rgb(210, 210, 210);
}
@media (max-width:600px) {
  .caseStudyNavbar{
    flex-direction: column;
    height: auto;
    top: 14%;
  }
  .caseStudySearchbar{
    margin-left: 48px;
  }
  .caseStudyNavContent1,
  .caseStudyNavContent2,.caseStudyNavContent3{
    margin-top: 10px;
  }
  .caseStudyNavContent3{
    margin-bottom: 10px;
  }
}
